import _ from "lodash/fp";
import { compile } from "path-to-regexp";

const routes = {
  DASHBOARD: "/dashboard",
  DQ_DASHBOARD: "/quality_dashboard",
  CATALOG_DASHBOARD: "/catalog_dashboard",
  METADATA_DASHBOARD: "/metadata_dashboard",
  USAGE_DASHBOARD: "/usage_dashboard",
  PERMISSIONS_DASHBOARD: "/permissions_dashboard",
  DS_DASHBOARD: "/data_stewards_dashboard",
  MARKETING_DASHBOARD: "/marketing_dashboard",
  IMPACT_DASHBOARD: "/impact_dashboard",
  EVOLUTIONARY_RULES_DASHBOARD: "/evolutionary_rules_dashboard",
  NATURGY_GOVERNANCE_DASHBOARD: "/gobierno_naturgy_dashboard",
  LINEAGE_DASHBOARD: "/lineage_dashboard",
  HOME: "/",
  UNAUTHORIZED: "/unauthorized"
};

const linkTo = _.mapValues(compile, routes);

export { routes as default, linkTo };
