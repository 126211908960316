import _ from "lodash/fp";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, Menu, Image } from "semantic-ui-react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { logout } from "@truedat/auth/sessions/routines";
import { NotificationsMenu } from "@truedat/audit/components";
import routes from "@truedat/core/routes";
import styles from "styles/topmenu.less";
import logo from "assets/logo_sofia.png";
import naturgyLogo from "assets/naturgy.png";
import ChangePwd from "../ChangePwd";

const imageWrapper = {
  display: "flex",
  margin: "0",
  alignItems: "center"
};

const image = {
  width: "120px",
  height: "36px"
};

export const TopMenu = ({
  canChangePassword,
  username,
  logout,
  hasItemsInCart
}) => (
  <Menu borderless className={styles.topmenu} fixed="top">
    <Menu.Item className="gnf-logo">
      <a href="/">
        <Image src={logo} size="tiny" />
      </a>
    </Menu.Item>
    <Menu.Item className="gnf-claim">
      <FormattedMessage id="header.claim" />
    </Menu.Item>
    <Menu.Menu position="right">
      <Image style={imageWrapper}>
        <Image src={naturgyLogo} style={image} />
      </Image>
      {hasItemsInCart ? (
        <Menu.Item
          icon="shopping cart"
          as={Link}
          to={routes.GRANTS_REQUESTS_CHECKOUT}
        />
      ) : null}
      <NotificationsMenu />
      <Dropdown item text={username} icon="user">
        <Dropdown.Menu>
          <Dropdown.Header content={username} />
          <Dropdown.Divider />
          <Dropdown.Item name="logout" onClick={logout}>
            <FormattedMessage id="navigation.logout" />
          </Dropdown.Item>
          {canChangePassword ? <ChangePwd /> : null}
          <Dropdown.Divider />
          <Dropdown.Item disabled>
            <FormattedMessage
              id="version"
              defaultMessage="Sofia v{version}"
              // eslint-disable-next-line no-undef
              values={{ version: VERSION }}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Menu>
  </Menu>
);

TopMenu.propTypes = {
  username: PropTypes.string,
  logout: PropTypes.func,
  canChangePassword: PropTypes.bool,
  hasItemsInCart: PropTypes.boolean
};

const mapStateToProps = ({ authentication, grantRequestsCart }) => ({
  username: authentication.user_name || "unknown user",
  canChangePassword: _.includes("pwd")(authentication.amr),
  hasItemsInCart: !!grantRequestsCart?.structures?.length
});

export default connect(mapStateToProps, { logout })(TopMenu);
