import _ from "lodash/fp";
import React from "react";
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";

const translateDecorator = text => <FormattedMessage id={text} />;

const dateDecorator = date =>
  date && <Moment locale="es" date={date} format="YYYY-MM-DD HH:mm" />;

const initialState = [
  { name: "name", sort: { name: "name.raw" }, width: 7 },
  {
    name: "type",
    fieldDecorator: type => translateDecorator(`ingests.type.${type}`),
    width: 1
  },
  {
    name: "status",
    fieldDecorator: field => translateDecorator(`ingests.status.${field}`),
    width: 1
  },
  {
    name: "domain",
    fieldSelector: _.path("domain.name"),
    sort: { name: "domain.name.raw" },
    width: 1
  },
  {
    name: "content",
    header: "system",
    fieldSelector: _.path("content.System"),
    sort: { name: "content.Product.raw" },
    width: 1
  },
  {
    name: "content",
    header: "structure",
    fieldSelector: _.path("content.Structure"),
    width: 4
  },
  {
    name: "content",
    header: "datalake",
    fieldSelector: _.path("content.Datalake"),
    sort: { name: "content.Datalake.raw" },
    width: 1
  },
  {
    name: "execution_status",
    sort: { name: "execution_status.sort" }
  },
  {
    name: "last_execution",
    sort: { name: "last_execution" },
    fieldDecorator: dateDecorator,
    textAlign: "center",
    width: 2
  }
];

export const ingestsColumns = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
