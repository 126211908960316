import _ from "lodash/fp";
import { defaultGrantsTableColumns } from "@truedat/dd/selectors";

const defaultColumnsWithoutMetadata = _.filter(
    _.negate(_.propEq("name", "metadata"))
)(defaultGrantsTableColumns);

const addedColumnaAprobador = [
    {
        name: "domain",
        fieldSelector: _.path("data_structure_version.domain.name")
    }
];

const initialState = [
    ...defaultColumnsWithoutMetadata,
    ...addedColumnaAprobador
];

export const grantsTableColumns = (state = initialState) => state;
