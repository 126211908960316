import _ from "lodash/fp";
import React from "react";
import { Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

const iconDecorator = field => (field > 0 ? <Icon name="check circle" /> : "");

const translateDecorator = text => <FormattedMessage id={text} />;

const initialState = {
  concepts: [
    {
      name: "name",
      sort: { name: "name.raw" },
      width: 5
    },
    {
      name: "status",
      sort: { name: "status" },
      fieldDecorator: field => translateDecorator(`concepts.status.${field}`),
      width: 1
    },
    {
      name: "type_label",
      width: 2
    },
    {
      name: "domain_parents",
      header: "organization",
      fieldSelector: ({ domain_parents }) => {
        return _.reverse(domain_parents)
          .map(a => a.name)
          .join(" > ");
      },
      width: 2
    },
    {
      name: "content",
      header: "functional_owner",
      fieldSelector: _.path("content.data_steward"),
      sort: { name: "content.data_steward.raw" },
      width: 2
    },
    {
      name: "rule_count",
      sort: { name: "rule_count" },
      fieldDecorator: iconDecorator,
      textAlign: "center",
      width: 1
    },
    {
      name: "link_count",
      sort: { name: "link_count" },
      fieldDecorator: iconDecorator,
      textAlign: "center",
      width: 1
    }
  ]
};

export const conceptsColumns = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
