import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { Redirector } from "@truedat/core/components";
import {
  Auth0Callback,
  OpenIDConnect,
  PrivateRoute,
  UnauthorizedRoute
} from "@truedat/auth/components";
import { CALLBACK, LOGIN, UNAUTHORIZED } from "@truedat/core/routes";
import Unauthorized from "components/Unauthorized";
import Navigation from "components/nav/Navigation";
import Loadable from "react-loadable";
import { Loading } from "@truedat/core/components";
import { CanInitLoader } from "@truedat/auth/components";
import Login from "./login/Login";
import AppSetup from "./setup/AppSetup";

const AuthMethodsLoader = Loadable({
  loader: () => import("@truedat/auth/sessions/components/AuthMethodsLoader"),
  loading: Loading
});

export const AppRoutes = ({ redirect, canInit, canInitLoaded }) => {
  return (
    <Switch>
      <Route exact path="/oidc" render={() => <OpenIDConnect />} />
      <Route
        exact
        path={CALLBACK}
        render={() => (
          <>
            <AuthMethodsLoader />
            <Auth0Callback />
          </>
        )}
      />
      <Route
        exact
        path={LOGIN}
        render={() => (
          <>
            <CanInitLoader />
            {canInitLoaded && canInit && <AppSetup />}
            {canInitLoaded && !canInit && <Login />}
          </>
        )}
      />
      {!redirect && (
        <UnauthorizedRoute exact path={UNAUTHORIZED} component={Unauthorized} />
      )}
      {redirect && <Redirector redirect={redirect} />}
      {!redirect && <PrivateRoute path="/" component={Navigation} />}
    </Switch>
  );
};

AppRoutes.propTypes = {
  redirect: PropTypes.string,
  canInit: PropTypes.bool,
  canInitLoaded: PropTypes.bool
};

const mapStateToProps = ({
  auditRedirect,
  authRedirect,
  conceptRedirect,
  configurationRedirect,
  ingestRedirect,
  domainRedirect,
  structureRedirect,
  groupRedirect,
  userRedirect,
  relationRedirect,
  roleRedirect,
  ruleRedirect,
  ruleImplementationRedirect,
  searchRedirect,
  templateRedirect,
  sourceRedirect,
  canInit,
  canInitLoaded
}) => ({
  redirect:
    auditRedirect ||
    authRedirect ||
    conceptRedirect ||
    configurationRedirect ||
    ingestRedirect ||
    domainRedirect ||
    structureRedirect ||
    groupRedirect ||
    userRedirect ||
    relationRedirect ||
    roleRedirect ||
    ruleRedirect ||
    ruleImplementationRedirect ||
    searchRedirect ||
    templateRedirect ||
    sourceRedirect,
  canInit,
  canInitLoaded
});

export default compose(withRouter, connect(mapStateToProps))(AppRoutes);
