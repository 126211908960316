import _ from "lodash/fp";
import React from "react";
import Moment from "react-moment";
import { Icon, Popup } from "semantic-ui-react";
import { defaultStructureFieldColumns } from "@truedat/dd/selectors";

const iconDecorator = field =>
  field ? <Popup trigger={<Icon name="check circle" />} content={field} /> : "";

const dateDecorator = date => (
  <Moment locale="es" date={date} format="YYYY-MM-DD HH:mm" />
);

const customStructureFieldsColumns = [
  { name: "DataType" },
  { name: "datatype" },
  {
    name: "inserted_at",
    fieldDecorator: dateDecorator
  },
  {
    name: "formula",
    fieldDecorator: iconDecorator
  }
];

export const structureFieldsColumns = () => {
  return _.concat(
    defaultStructureFieldColumns,
    customStructureFieldsColumns
  );
};
