import _ from "lodash/fp";
import TemplatesLoader from "@truedat/df/templates/components/TemplatesLoader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeGetSubscopes } from "@truedat/df/selectors/subscopedTemplates";
import { linkTo } from "@truedat/core/routes";
import React from "react";
import { useAuthorized } from "@truedat/core/hooks";
import { CONCEPTS, CONCEPTS_PENDING, CONCEPTS_DEPRECATED, CONCEPTS_SUBSCOPED, DASHBOARD, CONCEPT_LINKS_MANAGEMENT, CONCEPTS_BULK_UPLOAD_EVENTS } from "@truedat/core/routes";
import { Submenu } from "@truedat/core/components";
import routes from "../../routes";

const items = (bgSubscopes = []) => [
  {
    name: "concepts",
    routes: [CONCEPTS],
    permissions: ["business_glossary_view"]
  },
  ...bgSubscopes,
  {
    name: "concepts_management",
    routes: [CONCEPTS_PENDING],
    permissions: ["business_glossary_management"]
  },
  {
    name: "concepts_deprecated",
    routes: [CONCEPTS_DEPRECATED],
    groups: ["business_glossary_management"],
  },
  {
    name: "concepts_links_management",
    routes: [CONCEPT_LINKS_MANAGEMENT],
    permissions: ["business_glossary_management"]
  },
  {
    name: "dashboard",
    routes: [DASHBOARD],
    permissions: ["dashboards"]
  },
  {
    name: "data_stewards_dashboard",
    routes: [routes.DS_DASHBOARD],
    permissions: ["dashboards"]
  },
  {
    name: "concepts_upload_events",
    routes: [CONCEPTS_BULK_UPLOAD_EVENTS],
    groups: ["business_glossary_management"],
  }
];
const subscopeItems = _.flow(
  _.map((bgSubscope) => ({
    name: bgSubscope,
    routes: [linkTo.CONCEPTS_SUBSCOPED({ subscope: bgSubscope })],
    groups: ["business_glossary_view"],
  })),
  _.orderBy(["name"], ["asc"])
);

export const GlossaryMenu = ({ bgSubscopes }) => {
  const authorized = useAuthorized([
    "business_glossary_view",
    "business_glossary_management",
    "dashboards"
  ]);

  return authorized ? (
    // Use TemplatesLoader without scope, so that templates are saved in the
    // "allTemplates" reducer, and thus do not collide with other
    // <TemplatesLoader scope="..."> that collect templates in the "templates"
    // reducer.
    <>
      <TemplatesLoader />
      <Submenu
        items={items(subscopeItems(bgSubscopes))}
        icon="tags"
        name="glossary"
      />
    </>
  ) : null;
};

GlossaryMenu.propTypes = {
  bgSubscopes: PropTypes.array,
};

const makeMapStateToProps = () => {
  const getSubscopes = makeGetSubscopes("bg");
  const mapStateToProps = (state, props) => ({
    bgSubscopes: getSubscopes(state, props),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(GlossaryMenu);

