import React from "react";
import { withRouter } from "react-router-dom";
import { ErrorBoundary } from "@truedat/core/components";
import { I18nRoutes } from "@truedat/core/i18n/components";
import { SubscriptionRoutes } from "@truedat/audit/components";
import { RoleRoutes, UserRoutes } from "@truedat/auth/components";
import { ConceptRoutes, DomainRoutes } from "@truedat/bg/components";
import {
  DictionaryRoutes,
  LineageRoutes,
  GrantRoutes
} from "@truedat/dd/components";
import { QualityRoutes } from "@truedat/dq/components";
import { AiRoutes } from "@truedat/ai/components";
import { IngestRoutes } from "@truedat/ie/components";
import { RelationRoutes } from "@truedat/lm/components";
import { TemplateRoutes } from "@truedat/df/components";
import { SearchRoutes } from "@truedat/se/components";
import { HierarchyRoutes } from "@truedat/df/components";
import {
  ConfigurationRoutes,
  JobRoutes,
  SourceRoutes
} from "@truedat/cx/components";
import DashboardRoutes from "./DashboardRoutes";

const Routes = () => (
  <ErrorBoundary>
    <DashboardRoutes />
    <DomainRoutes />
    <ConceptRoutes />
    <DictionaryRoutes />
    <QualityRoutes />
    <LineageRoutes />
    <GrantRoutes />
    <UserRoutes />
    <RoleRoutes />
    <TemplateRoutes />
    <I18nRoutes />
    <HierarchyRoutes />
    <IngestRoutes />
    <RelationRoutes />
    <SearchRoutes />
    <SourceRoutes />
    <JobRoutes />
    <ConfigurationRoutes />
    <SubscriptionRoutes />
    <AiRoutes />
  </ErrorBoundary>
);

export default withRouter(Routes);
