export default {
  //BG
  "concepts.actions.deprecate": "Borrar",
  "concepts.status.deprecated": "Borrado",
  "concepts.props.type_label": "Tipo",

  "filters.agrupacion": "Agrupación información",
  "filters.confidencialidad": "Confidencialidad",
  "filters.criticidad": "Criticidad",
  "filters.data_officer": "Data Officer",
  "filters.data_owner": "Data Owner",
  "filters.data_steward": "Data Steward",
  "filters.it_data_stewards": "IT Data Steward",
  "filters.Responsable/Propietario Informe": "Data Steward Informe",
  "filters.domain_parents": "Organización",
  "filters.domain": "Organización",
  "filters.domain_ids": "Organización",
  "filters.dominio información": "Dominio Información de Gestión",
  "filters.dominio_informacion": "Dominio Información de Gestión",
  "filters.dominio_informacion.Si": "Si",
  "filters.modelo_datos": "Mapa de datos",
  "filters.modelo_datos_1": "Mapa de datos nivel 1",
  "filters.modelo_datos_2": "Mapa de datos nivel 2",
  "filters.Mapa_datos_1": "Mapa de datos nivel 1",
  "filters.Mapa_datos_2": "Mapa de datos nivel 2",
  "filters.nivel_informe": "Nivel Informe",
  "filters.Tipo": "Tipo",
  "filters.idioma": "Idioma",
  "filters.naturaleza": "Naturaleza",
  "filters.pais": "País",
  "filters.sensible": "Sensible",
  "filters.sujeto_gdpr": "Sujeto a GDPR",
  "filters.temporalidad": "Temporalidad",
  "filters.business_concept_id": "Término de negocio",
  "filters.domain_id": "Dominio",
  "filters.current": "Filtro actual",
  "filters.DateInPath": "Fecha de ingesta",
  "filters.VersionInPath": "Versión de ingesta",
  "filters.ColumnSQLStandard": "Columna SQL compatible",
  "filters.Sep_Thousands": "Separador Miles",
  "filters.Sep_Decimal": "Separador Decimal",
  "filters.Separator": "Separador",
  "filters.System": "Sistema",
  "filters.Product": "Producto",
  "filters.Header": "Cabecera",
  "filters.FullIncInPath": "Tipo de ingesta",
  "filters.Encoding": "Codificación",
  "filters.Scheduled": "Programado",
  "filters.SourceType": "Tipo de origen",
  "filters.thousand": "Separador de Miles",
  "filters.periodicity": "Periodicidad",
  "filters.periodicity.Daily": "Diaria",
  "filters.periodicity.Monthly": "Mensual",
  "filters.periodicity.Weekly": "Semanal",
  "filters.tags": "Etiquetas",
  "filters.priorization": "Priorización",
  "filters.impact": "Impacto económico",

  "header.claim": "Herramienta de Gobierno y Ciclo de Vida del Dato",

  "structure.lopd": "Sensibilidad GDPR",

  "concepts.props.functional_owner": "Data steward",
  "concepts.props.criticidad": "Criticidad",
  "concepts.props.dominio_informacion": "Información de gestión",
  "concepts.props.direccion_general": "Dirección general",
  "concepts.props.business": "Negocio",
  "concepts.props.organization": "Organización",

  "source.bc_relacionado": "Relacionado con",
  "target.bc_relacionado": "Relacionado con",
  "source.bc_calculo": "Se calcula en base a",
  "target.bc_calculo": "Parte del calculo de",
  "source.bc_parent": "Concepto padre de",
  "target.bc_parent": "Concepto hijo de",

  "permission.create_acl_entry":
    "Asignar rol a un usuario/grupo en la organización",
  "permission.create_business_concept": "Crear términos",
  "permission.delete_business_concept": "Borrar términos",
  "permission.deprecate_business_concept": "Archivar términos",
  "permission.publish_business_concept": "Aprobar términos",
  "permission.reject_business_concept": "Rechazar términos",
  "permission.send_business_concept_for_approval":
    "Solicitar aprobación de términos",
  "permission.view_approval_pending_business_concepts":
    "Ver términos de negocio pendientes de aprobación",
  "permission.view_deprecated_business_concepts":
    "Ver términos de negocio archivados",
  "permission.view_draft_business_concepts":
    "Ver términos de negocio en estado borrador",
  "permission.view_published_business_concepts":
    "Ver términos de negocio publicados",
  "permission.view_rejected_business_concepts":
    "Ver términos de negocio rechazados",
  "permission.view_versioned_business_concepts":
    "Ver términos de negocio que hayan sido modificados",
  "permission.update_acl_entry":
    "Modificar rol de un usuario/grupo en una organización",
  "permission.update_business_concept": "Editar términos de negocio",
  "permission.manage_confidential_business_concepts": "Términos confidenciales",
  "permission.manage_business_concept_links": "Vincular datos a términos",
  "permission.manage_confidential_structures": "Estructuras confidenciales",

  "login.form.actions.auth0.login": "Entrar",
  "login.form.actions.oidc.login": "Entrar",
  "login.description": "Sofia",
  "login.form.action.login": "Iniciar sesión",
  "login.form.or": "O",
  "login.form.aditionalaccess": "Otros accesos",

  "permission.create_domain": "Crear dominios",
  "permission.delete_acl_entry":
    "Borrar el rol de un usuario/grupo en una organización",
  "permission.delete_domain": "Borrar dominios",
  "permission.manage_business_concept_alias": "Editar tags",
  "permission.update_domain": "Actualizar dominios",
  "permission.view_domain": "Ver dominios",

  "user.acl.domain": "Organización",

  "validation.header.message.error": "¡Faltan campos por rellenar!",

  // DD
  "structure.test": "GDPR",

  "structure.type.Field.text": "Campo", //Datalake
  "structure.type.Field.icon": "columns",

  "structure.type.structure.text": "Estructura", //Datalake
  "structure.type.structure.icon": "table",

  "structure.type.Column.text": "Columna", //Datalake/PowerBI
  "structure.type.Column.icon": "columns",

  "structure.type.Table.text": "Tabla", //PowerBI
  "structure.type.Table.icon": "table",

  "structure.type.Measure.text": "Métrica", //PowerBI
  "structure.type.Measure.icon": "tachometer alternate",

  "structure.type.Calculated Table Column.text": "Columna Calculada", //PowerBI
  "structure.type.Calculated Table Column.icon": "columns",

  "structure.type.Calculated Column.text": "Columna Calculada", //PowerBI
  "structure.type.Calculated Column.icon": "columns",

  "structure.type.Calculated Table.text": "Tabla Calculada", //PowerBI
  "structure.type.Calculated Table.icon": "table",

  "structure.type.Workspace.text": "Espacio de trabajo", //PowerBI
  "structure.type.Workspace.icon": "folder",

  "structure.type.worksheet.text": "Hoja de cálculo", //PowerBI/Tableau
  "structure.type.worksheet.icon": "sticky note",

  "structure.type.workbook.text": "Libro de trabajo", //Tableau
  "structure.type.workbook.icon": "book",

  "structure.type.dashboard.text": "Tablero", //Tableau
  "structure.type.dashboard.icon": "chart bar",

  "structure.type.datasource.text": "Origen de datos", //Tableau
  "structure.type.datasource.icon": "table",

  "structure.type.measure.text": "Medida", //Tableau
  "structure.type.measure.icon": "tachometer alternate",

  "structure.type.dimension.text": "Dimensión", //Tableau
  "structure.type.dimension.icon": "cube",

  "structure.type.Project.text": "Proyecto", //MicroStrategy
  "structure.type.Project.icon": "briefcase",

  "structure.type.Document.text": "Documento", //MicroStrategy
  "structure.type.Document.icon": "file outline",

  "structure.type.Attribute.text": "Atributo", //MicroStrategy
  "structure.type.Attribute.icon": "cube",

  "structure.type.Metric.text": "Métrica", //MicroStrategy
  "structure.type.Metric.icon": "tachometer alternate",

  "structure.type.Folder.text": "Carpeta", //MicroStrategy/Datalake
  "structure.type.Folder.icon": "folder",

  "structure.type.Report.text": "Informe", //MicroStrategy/PowerBI
  "structure.type.Report.icon": "chart bar",

  "structure.type.Database.text": "Base de Datos", //SQLServer/Glue
  "structure.type.Database.icon": "database",

  "structure.type.string.text": "Texto", //Glue
  "structure.type.string.icon": "font",

  "structure.type.int.text": "Entero", //Glue
  "structure.type.int.icon": "hashtag",

  "structure.type.tinyint.text": "Entero muy pequeño", //Glue
  "structure.type.tinyint.icon": "hashtag",

  "structure.type.smallint.text": "Entero pequeño", //Glue
  "structure.type.smallint.icon": "hashtag",

  "structure.type.decimal.text": "Decimal", //Glue
  "structure.type.decimal.icon": "hashtag",

  "structure.type.timestamp.text": "Marca de tiempo", //Glue
  "structure.type.timestamp.icon": "clock outline",

  "structure.type.USER_TABLE.text": "Tabla de usuario", //SQLServer
  "structure.type.USER_TABLE.icon": "table",

  "structure.type.Schema.text": "Esquema", //SQLServer
  "structure.type.Schema.icon": "object group outline",

  "structure.type.BASE TABLE.text": "Tabla", //Redshift
  "structure.type.BASE TABLE.icon": "table",

  "structure.type.VIEW.text": "View", //Redshift
  "structure.type.VIEW.icon": "table",

  "structure.type.character varying.text": "String", //Redshift
  "structure.type.character varying.icon": "font",

  "structure.type.date.text": "Fecha", //Redshift
  "structure.type.date.icon": "calendar alternate outline",

  "structure.type.integer.text": "Entero", //Redshift
  "structure.type.integer.icon": "hashtag",

  "structure.type.bigint.text": "Entero grande", //Redshift
  "structure.type.bigint.icon": "hashtag",

  "structure.type.numeric.text": "Numérico", //Redshift
  "structure.type.numeric.icon": "hashtag",

  "structure.type.timestamp without time zone.text": "Marca de tiempo sin TZ", //Redshift
  "structure.type.timestamp without time zone.icon": "clock outline",

  "structure.type.External Table.text": "Tabla Externa",
  "structure.type.External Table.icon": "table",

  "structure.type.External Column.text": "Columna Externa",
  "structure.type.External Column.icon": "columns",

  "structure.type.PartitionKey.text": "Clave de partición",
  "structure.type.PartitionKey.icon": "columns",

  "structure.type.filter.text": "Filtro",
  "structure.type.filter.icon": "filter",

  "structure.type.double.text": "double",
  "structure.type.double.icon": "hashtag",

  "structure.field.DataType": "Tipo de Dato",
  "structure.field.datatype": "Tipo de Dato",
  "structure.field.menu.link": "Vincular a término",
  "structure.field.formula": "Fórmula",
  "structure.field.inserted_at": "Fecha de modificación",

  "filters.warm_storage": "Almacenamiento en caliente",
  "filters.gdpr_table": "Datos personales (GDPR) - Nivel Tabla",
  "filters.data_map_1": "Mapa de datos nivel 1",
  "filters.data_map_2": "Mapa de datos nivel 2",
  "filters.Datos confidenciales": "Datos confidenciales",

  "filters.has_links": "Vinculación",
  "filters.has_links.Con campos conectados a datos": "Con vinculación",
  "filters.has_links.Sin campos conectados a datos": "Sin vinculación",
  "filters.has_links.With fields connected to data": "Con vinculación",
  "filters.has_links.No fields connected to data": "Sin vinculación",

  "filters.sensitivity_table": "Sensible o confidencial - Nivel Tabla",
  "filters.functional_name": "Nombre Funcional",
  "filters.description": "Descripción",
  "filters.profundidad": "Profundidad histórica",
  "filters.frecuency": "Frecuencia de carga",
  "filters.disponibilidad": "Disponibilidad del dato",
  "filters.profile_structure_operators": "Profile - operadores",
  "filters.profile_structure_scheduler": "Profile - programado",
  "filters.profile_structure_state": "Profile - estado",
  "filters.campo_calculado": "Campo calculado",

  "filters.class.raw": "Clase",
  "filters.class.raw.field": "Campo",

  "ruleImplementation.props.campo": "Campo",
  "ruleImplementation.props.campo.placeholder": "Seleccione el campo",

  //IE
  "ingests.props.type": "Tipo",
  "ingests.props.structure": "Estructura",
  "ingests.props.datalake": "Datalake",
  "ingests.props.product": "Producto",
  "ingests.props.system": "Sistema",
  "ingests.props.name": "Proceso",

  "ingests.type.System": "Sistema",
  "ingests.type.Download": "Ofuscación",
  "ingests.type.Delivery": "Entrega",
  "ingests.type.Ingest": "Ingesta",

  "ingests.actions.create": "Nuevo Proceso",
  "ingests.actions.delete.confirmation.header": "Borrar proceso",
  "ingests.actions.upload.confirmation.header": "Subir fichero procesos",
  "ingests.header.duplicate": "Duplicar Proceso",
  "ingestLink.actions.delete.confirmation.content":
    "Se eliminará la vinculación entre el proceso y la estructura. ¿Estás seguro?",
  "ingests.crumbs.top": "Procesos",
  "ingests.header": "Procesos",
  "ingests.header.edit": "Editar Proceso",
  "ingests.subheader": "Consultar y gestionar procesos",
  "ingests.search.placeholder": "Buscar procesos...",
  "ingests.search.results.empty":
    "No se han encontrado procesos para esta búsqueda",
  "alert.ingestAction.failed.header": "Error creando proceso",
  "alert.upload.success.content":
    "Se han insertado correctamente {count} procesos",
  "navigation.ingests": "Procesos",
  "navigation.ingests.ingests": "Procesos",
  "navigation.glossary.taxonomy": "Organizaciones",
  "tabs.ie.ingest": "Proceso",
  "tabs.ie.relationsIngests": "Procesos relacionados",
  "tabs.se.ingests": "Procesos",
  "ingest.relatedTo.ingest": "Relacionado con proceso",
  "ingestRelations.relatedDataIngest": "Procesos relacionados",

  "ingestsExecutions.props.transmission_time": "Tiempo de transmisión (mm:ss)",
  "ingestsExecutions.props.status": "Estado Ejecución",

  //AppSetup
  "appSetup.welcome.message": "Bienvenido a Truedat",
  "appSetup.configuration.message":
    "Parece que todo se ha instalado correctamente. Vamos a comenzar a configurar la aplicación para poder empezar a usarla tras unos sencillos pasos",
  "appSetup.begin.button": "Comenzar",

  // ######## Non english variables

  "tabs.bg.relations_business_concept": "Términos relacionados",
  "conceptRelations.relatedConcept": "Término relacionado",
  "conceptRelations.empty":
    "Este término no tiene términos relacionados actualmente",
  "relations.actions.business_concept.create": "Añadir término relacionado",

  "concept.events.add_concept_field": "asoció un dato al término",
  "concept.events.concept_deprecated": "Borró el término",
  "concept.events.delete_concept_field": "desasoció un dato del término",

  "concepts.actions.create": "Nuevo Término",
  "concepts.actions.delete.confirmation.content":
    "Se eliminará este término del glosario. ¿Estás seguro?",
  "concepts.actions.delete.confirmation.header": "Borrar Término",
  "concepts.actions.deprecate.confirmation.header": "Borrar concepto",
  "tabs.bg.concept": "Término",

  "concepts.crumbs.top": "Glosario de Términos",
  "concepts.header": "Glosario de Términos",
  "concepts.props.domain": "Organización",
  "concepts.props.name": "Términos de negocio / Informes",

  "concepts.props.has_rules": "Reglas de Calidad",
  "concepts.props.has_links": "Vinculación",
  "concepts.props.last_change_at": "Fecha de modificación",
  "concepts.subheader": "Consultar y gestionar términos de negocio",
  "concepts.search.placeholder": "Buscar términos...",

  "conceptLink.actions.delete.confirmation.content":
    "Se eliminará la vinculación entre el término y la estructura. ¿Estás seguro?",
  "conceptLinks.empty":
    "Este término no tiene estructuras vinculadas actualmente",
  "conceptLink.ou": "Organización",

  "parentConcept.selector.label": "Término padre",

  "domain.error.existing.business.concept":
    "({text}) No se puede eliminar un dominio que contenga término de negocio",

  "domain.selector.label": "Organización",
  "domain.selector.placeholder": "Selecciona una organización...",

  "domains.actions.create": "Nueva organización",
  "domains.search.placeholder": "Buscar organizaciones...",
  "domains.subheader":
    "Gestionar las organizaciones de negocio y los dominios de gobierno de datos",

  "concepts.rules.empty": "Ese término no tiene reglas de calidad",
  "quality.business_concept": "Término",
  "rules.filters.current_business_concept_version": "Término",

  "concepts.has_links.linked_terms": "Con campos conectados a datos",
  "concepts.has_links.not_linked_terms": "Sin campos conectados a datos",

  "sidemenu.catalog_dashboard": "Dashboard",
  "sidemenu.metadata_dashboard": "Dashboard de Metadatos",
  "sidemenu.my_loads": "Mis Cargas",
  "sidemenu.referenceData": "Datos de Referencia",
  "sidemenu.quality_dashboard": "Dashboard",
  "sidemenu.implementations_management": "Borradores",
  "sidemenu.implementations_deprecated": "Archivadas",
  "sidemenu.executions": "Mis Ejecuciones de Calidad",
  "sidemenu.data_stewards_dashboard": "Dashboard Data Stewards",
  "sidemenu.marketing_dashboard": "Buscador informes comercialización",
  "sidemenu.taxonomy": "Organizaciones",
  "sidemenu.concepts": "Glosario",
  "sidemenu.structures": "Catálogo",
  "sidemenu.rules": "Calidad",
  "sidemenu.ingests": "Procesos",
  "sidemenu.usage": "Uso",
  "sidemenu.usage_dashboard": "Dashboard",
  "sidemenu.permissions_dashboard": "Dashboard de permisos",
  "sidemenu.pending_notes": "Notas pendientes",
  "sidemenu.structure_tags": "Etiquetas",
  "sidemenu.structure_types": "Tipos",
  "sidemenu.info": "Documentación",
  "sidemenu.info.hover": "Enlace a los manuales de Sofía",
  "sidemenu.impact_dashboard": "Dashboard de impacto y prioridad",
  "sidemenu.evolutionary_rules_dashboard": "Dashboard de evolutivo de reglas",
  "sidemenu.naturgy_governance_dashboard": "Dashboard de Gobierno Naturgy",
  "sidemenu.graphs": "Linaje",

  "tabs.domains": "Organizaciones",

  "templates.tabs.bg": "Glosario de Términos",
  "templates.tabs.gr": "Accesos",

  "filters.sensitivity_field": "Sensible o confidencial - Nivel Campo",
  "filters.GDPR_field": "Datos personales (GDPR) - Nivel Campo",

  "structures.header": "Catálogo",
  "structures.subheader": "Catálogo de datos como tablas o ficheros",

  // Types ingests

  // DQ
  "filters.current_business_concept_version": "Término",
  "filters.periodicidad": "Periodicidad",
  "filters.label_domain": "Dominio de las etiquetas",
  "filters.domain_label": "Etiquetas del Dominio",

  "rule.props.result_type.errors_number": "Número de errores",
  "ruleResult.details.props.Query": "Consulta",
  "ruleResult.details.props.evidences_sql": "Consulta de errores",
  "ruleResult.details.props.warning": "Aviso",

  "ruleImplementations.props.records": "Registros",
  "ruleImplementations.props.errors": "Errores",
  "ruleImplementations.props.dataset": "Estructura",

  //DF
  "templates.tabs.ie": "Procesos",
  "template.selector.label": "Tipo",

  //AUTH
  "permission.create_ingest": "Crear Proceso",
  "permission.delete_ingest": "Borrar Proceso",
  "permission.deprecate_ingest": "Archivar Proceso",
  "permission.publish_ingest": "Publicar Proceso",
  "permission.reject_ingest": "Rechazar Proceso",
  "permission.send_ingest_for_approval": "Solicitar aprobación de procesos",
  "permission.update_ingest": "Actualizar Proceso",
  "permission.view_approval_pending_ingests":
    "Ver proceso pendiente de aprobación",
  "permission.view_deprecated_ingests": "Ver procesos archivados",
  "permission.view_draft_ingests": "Ver procesos en estado borrador",
  "permission.view_published_ingests": "Ver procesos publicados",
  "permission.view_rejected_ingests": "Ver procesos rechazados",
  "permission.view_versioned_ingests": "Ver procesos versionados",
  "permission.manage_ingest_relations": "Vincular datos a procesos",

  "source.business_concept_to_field_master": "Relación master",
  "target.business_concept_to_field_master": "Relación master",

  ingest_to_ingest: "Entregada con",
  "source.ingest_to_ingest": "Entregada con",
  "target.ingest_to_ingest": "Ingestada por",

  "ingest.events.relation_deleted": "Relación eliminada",

  "ruleResult.props.Evidences": "Evidencias"
};
