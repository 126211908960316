import _ from "lodash/fp";
import React from "react";
import { defaultStructureProperties } from "@truedat/dd/selectors";

const defaultStructuresPropertiesWithoutGroup = _.filter(
  _.negate(_.propEq("name", "group"))
)(defaultStructureProperties);

const initialState = [
  ...defaultStructuresPropertiesWithoutGroup,
  {
    name: "test",
    fieldSelector: _.path("df_content.gdpr_table")
  }
];

export const structureProperties = (state = initialState, { type }) => {
  switch (type) {
    default:
      return state;
  }
};
