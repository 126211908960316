import React from "react";
import { useAuthorized } from "@truedat/core/hooks";
import {
  CONFIGURATIONS,
  JOBS,
  SOURCES,
  TEMPLATES,
  SUBSCRIPTIONS,
  HIERARCHIES,
  I18N_MESSAGES,
  RELATION_TAGS,
  TASKS
} from "@truedat/core/routes";
import { Submenu } from "@truedat/core/components";
import routes from "routes";

const items = [
  {
    name: "templates",
    routes: [TEMPLATES]
  },
  {
    name: "subscriptions",
    routes: [SUBSCRIPTIONS]
  },
  {
    name: "sources",
    routes: [SOURCES]
  },
  {
    name: "jobs",
    routes: [JOBS]
  },
  {
    name: "configurations",
    routes: [CONFIGURATIONS]
  },
  {
    name: "relations",
    routes: [RELATION_TAGS]
  },
  {
    name: "hierarchies",
    routes: [HIERARCHIES]
  },
  {
    name: "tasks",
    routes: [TASKS]
  },
  {
    name: "i18nMessages",
    routes: [I18N_MESSAGES]
  },
  {
    name: "usage_dashboard",
    routes: [routes.USAGE_DASHBOARD]
  },
  {
    name: "permissions_dashboard",
    routes: [routes.PERMISSIONS_DASHBOARD]
  }
];

export const AdminMenu = () => {
  const authorized = useAuthorized();
  return authorized ? (
    <Submenu items={items} icon="setting" name="admin" />
  ) : null;
};

export default AdminMenu;
