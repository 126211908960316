/* eslint-disable no-undef */
import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import routes from "routes";
import { useAuthorized } from "@truedat/core/hooks";
import DashboardMetabase from "../DashboardMetabase";
import {
  getGlossaryDashboardConfig,
  getQualityDashboardConfig,
  getCatalogDashboardConfig,
  getMetadataDashboardConfig,
  getUsageDashboardConfig,
  getPermissionsDashboardConfig,
  getDataStewardsDashboardConfig,
  getMarketingDashboardConfig,
  getImpactDashboardConfig,
  getEvolutionaryRulesDashboardConfig,
  getNaturgyGovernanceDashboardConfig,
  getLineageDashboardConfig
} from "../../reducers/getDashboardConfig";

const qualityDashboardPermissions = ["view_quality_rule"];
const mainDashboardPermissions = ["view_dashboard"];
const catalogDashboardPermissions = ["view_str"];
const metadataDashboardPermissions = ["view_str"];
const usageDashboardPermissions = ["administrator"];
const permissionsDashboardPermissions = ["administrator"];
const dataStewardsDashboardPermissions = ["view_dashboard"];
const marketingDashboardPermissions = ["view_dashboard"];
const impactDashboardPermissions = ["view_quality_rule"];
const evolutionaryRulesDashboardPermissions = ["view_quality_rule"];
const naturgyGovernanceDashboardPermissions = ["administrator"];
const lineageDashboardPermissions = ["view_dashboard"];

const DashboardRoutes = () => {
  const authorized_dashboards = useAuthorized("dashboards");
  const authorized_dq = useAuthorized("quality");
  const authorized_dd = useAuthorized("data_dictionary");
  const authorized_admin = useAuthorized("administrator");
  const authorized_dataStewards = useAuthorized("dashboards");
  const authorized_marketing = useAuthorized("dashboard");
  const authorized_impact = useAuthorized("quality");
  const authorized_lineage = useAuthorized("lineage");

  const authorized =
    authorized_dashboards ||
    authorized_dq ||
    authorized_dd ||
    authorized_admin ||
    authorized_dataStewards ||
    authorized_marketing ||
    authorized_impact ||
    authorized_lineage;

  const dashboardConfig = useSelector(getGlossaryDashboardConfig);
  const qualityDashboardConfig = useSelector(getQualityDashboardConfig);
  const catalogDashboardConfig = useSelector(getCatalogDashboardConfig);
  const metadataDashboardConfig = useSelector(getMetadataDashboardConfig);
  const usageDashboardConfig = useSelector(getUsageDashboardConfig);
  const permissionsDashboardConfig = useSelector(getPermissionsDashboardConfig);
  const dataStewardsDashboardConfig = useSelector(
    getDataStewardsDashboardConfig
  );
  const marketingDashboardConfig = useSelector(getMarketingDashboardConfig);
  const impactDashboardConfig = useSelector(getImpactDashboardConfig);
  const evolutionaryRulesDashboardConfig = useSelector(getEvolutionaryRulesDashboardConfig);
  const naturgyGovernanceDashboardConfig = useSelector(getNaturgyGovernanceDashboardConfig);
  const lineageDashboardConfig = useSelector(getLineageDashboardConfig);

  return authorized ? (
    <>
      {authorized_dashboards && !_.isNil(dashboardConfig) && (
        <Route
          exact
          path={routes.DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "General",
                  config: dashboardConfig,
                  id: dashboardConfig.content.dashboard_id,
                  permissions: mainDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dq && !_.isNil(qualityDashboardConfig) && (
        <Route
          exact
          path={routes.DQ_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Quality",
                  config: dashboardConfig,
                  id: dashboardConfig.content.quality_dashboard_id,
                  permissions: qualityDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dq && !_.isNil(impactDashboardConfig) && (
        <Route
          exact
          path={routes.IMPACT_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Impact vs Priorization",
                  config: impactDashboardConfig,
                  id: impactDashboardConfig.content.impact_dashboard_id,
                  permissions: impactDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dq && !_.isNil(evolutionaryRulesDashboardConfig) && (
        <Route
          exact
          path={routes.EVOLUTIONARY_RULES_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Evolutionary Rules",
                  config: evolutionaryRulesDashboardConfig,
                  id: evolutionaryRulesDashboardConfig.content.evolutionary_rules_dashboard_id,
                  permissions: evolutionaryRulesDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dq && !_.isNil(naturgyGovernanceDashboardConfig) && (
        <Route
          exact
          path={routes.NATURGY_GOVERNANCE_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Naturgy Governance",
                  config: naturgyGovernanceDashboardConfig,
                  id: naturgyGovernanceDashboardConfig.content.gobierno_naturgy_dashboard_id,
                  permissions: naturgyGovernanceDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dd && !_.isNil(catalogDashboardConfig) && (
        <Route
          exact
          path={routes.CATALOG_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Catalog",
                  config: catalogDashboardConfig,
                  id: catalogDashboardConfig.content.catalog_dashboard_id,
                  permissions: catalogDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dd && !_.isNil(metadataDashboardConfig) && (
        <Route
          exact
          path={routes.METADATA_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Metadata",
                  config: metadataDashboardConfig,
                  id: metadataDashboardConfig.content.metadata_dashboard_id,
                  permissions: metadataDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dd && !_.isNil(usageDashboardConfig) && (
        <Route
          exact
          path={routes.USAGE_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Usage",
                  config: usageDashboardConfig,
                  id: usageDashboardConfig.content.usage_dashboard_id,
                  permissions: usageDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dd && !_.isNil(permissionsDashboardConfig) && (
        <Route
          exact
          path={routes.PERMISSIONS_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Permissions",
                  config: permissionsDashboardConfig,
                  id: permissionsDashboardConfig.content
                    .permissions_dashboard_id,
                  permissions: permissionsDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dd && !_.isNil(dataStewardsDashboardConfig) && (
        <Route
          exact
          path={routes.DS_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Data Stewards",
                  config: dataStewardsDashboardConfig,
                  id: dataStewardsDashboardConfig.content
                    .data_stewards_dashboard_id,
                  permissions: dataStewardsDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_dd && !_.isNil(marketingDashboardConfig) && (
        <Route
          exact
          path={routes.MARKETING_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Marketing",
                  config: marketingDashboardConfig,
                  id: marketingDashboardConfig.content.marketing_dashboard_id,
                  permissions: marketingDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
      {authorized_lineage && !_.isNil(lineageDashboardConfig) && (
        <Route
          exact
          path={routes.LINEAGE_DASHBOARD}
          render={() => (
            <DashboardMetabase
              tabs={[
                {
                  tabName: "Lineage",
                  config: lineageDashboardConfig,
                  id: lineageDashboardConfig.content.lineage_dashboard_id,
                  permissions: lineageDashboardPermissions
                }
              ]}
            />
          )}
        />
      )}
    </>
  ) : null;
};

export default DashboardRoutes;
