import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Menu, Icon } from "semantic-ui-react";

// eslint-disable-next-line no-undef
const url = DOCUMENTATION_URL;

export const DocumentationMenu = () => {
  const sidebarVisible = useSelector(state => state.sidebarVisible);
  const { formatMessage } = useIntl();
  const title = formatMessage({
    id: "sidemenu.info",
    defaultMessage: "Information"
  });
  const hovering = formatMessage({
    id: "sidemenu.info.hover",
    defaultMessage: "Information"
  });
  return (
    <div>
      <Menu.Item className="selectable" as="div" name="info" title={hovering}>
        <a href={url} target="_blank" className="ui" rel="noreferrer">
          <Icon size="large" name="info" />
          {sidebarVisible && title}
        </a>
      </Menu.Item>
    </div>
  );
};

export default DocumentationMenu;
