import _ from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";
import { useAuthorized } from "@truedat/core/hooks";
import {
  EXECUTION_GROUPS,
  RULES,
  QUALITY_DASHBOARD,
  IMPLEMENTATIONS,
  IMPLEMENTATIONS_PENDING,
  IMPLEMENTATIONS_DEPRECATED
} from "@truedat/core/routes";
import { getQualityDashboardConfig } from "@truedat/core/selectors";
import { Submenu } from "@truedat/core/components";
import routes from "../../routes";

const items = [
  { name: "rules", routes: [RULES], groups: ["quality"] },
  { name: "implementations", routes: [IMPLEMENTATIONS], groups: ["quality"] },
  { name: "implementations_management", routes: [IMPLEMENTATIONS_PENDING], groups: ["quality_implementation_workflow_management"] },
  { name: "implementations_deprecated", routes: [IMPLEMENTATIONS_DEPRECATED], groups: ["quality_implementation_workflow_management"] },
  { name: "executions", routes: [EXECUTION_GROUPS], groups: ["quality_implementation_additional_actions"] },
  { name: "quality_dashboard", routes: [QUALITY_DASHBOARD], groups: ["quality"] },
  { name: "impact_dashboard", routes: [routes.IMPACT_DASHBOARD], groups: ["quality"] },
  { name: "evolutionary_rules_dashboard", routes: [routes.EVOLUTIONARY_RULES_DASHBOARD], groups: ["quality"] }
];

export const QualityMenu = () => {
  const authorized = useAuthorized(
    "quality",
    "quality_implementation_workflow_management",
    "quality_implementation_additional_actions"
  );

  const dashboardConfig = useSelector(getQualityDashboardConfig);

  const filteredItems = _.filter(
    ({ name }) => name != "quality_dashboard" || !_.isEmpty(dashboardConfig)
  )(items);

  return authorized ? (
    <Submenu items={filteredItems} icon="check square" name="quality" />
  ) : null;
};

export default QualityMenu;
