/* eslint-disable no-undef */
import React from "react";
import { connect } from "react-redux";
import ResponsiveEmbed from "react-responsive-embed";
import PropTypes from "prop-types";
import { UserDomainsFilter } from "@truedat/auth/users/components";
import { signConfiguration } from "@truedat/cx/routines";
import { Tab } from "semantic-ui-react";
import { ConfigurationTokenLoader } from "@truedat/cx/components";
import { DomainsLoader } from "@truedat/bg/taxonomy/components";

const SimpleDashboard = ({
  url,
  token,
  externalId,
  dashboardId,
  permissions
}) => {
  const src = token
    ? `${url}/embed/dashboard/${token}#bordered=false&titled=true`
    : "";
  return (
    <>
      <ConfigurationTokenLoader
        externalId={externalId}
        dashboardId={dashboardId}
      />
      <DomainsLoader actions={permissions} />
      <UserDomainsFilter />
      <ResponsiveEmbed src={src} scrolling={"yes"} />
    </>
  );
};

SimpleDashboard.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  externalId: PropTypes.string,
  dashboardId: PropTypes.object,
  permissions: PropTypes.object
};

export const DashboardMetabase = ({ configurationToken, tabs }) => {
  return (
    <SimpleDashboard
      url={tabs[0].config.content.metabase_url}
      token={configurationToken}
      externalId={tabs[0].config.external_id}
      dashboardId={tabs[0].id}
      permissions={tabs[0].permissions}
    />
  );
};

const mapDispatchToProps = { signConfiguration };

const mapStateToProps = ({ configurationToken }) => ({
  configurationToken
});

DashboardMetabase.propTypes = {
  configurationToken: PropTypes.object,
  tabs: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMetabase);
