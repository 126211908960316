import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { PASSWORD } from "@truedat/core/routes";

export const ChangePwd = () => (
  <Dropdown.Item name="change_pwd" as={Link} to={PASSWORD}>
    <Icon name="user outline" />
    <FormattedMessage id="navigation.change_pwd" />
  </Dropdown.Item>
);

export default ChangePwd;
