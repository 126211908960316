import React from "react";
import { useAuthorized } from "@truedat/core/hooks";
import { LINEAGE_EVENTS, GRAPHS } from "@truedat/core/routes";
import { Submenu } from "@truedat/core/components";
import routes from "../../routes";

const items = [
  {
    name: "graphs",
    routes: [GRAPHS],
    permissions: ["view_lineage"]
  },
  {
    name: "lineage_events",
    routes: [LINEAGE_EVENTS],
    permissions: ["view_lineage"]
  },
  {
    name: "dashboard",
    routes: [routes.LINEAGE_DASHBOARD],
    permissions: ["view_lineage"]
  }
];

export const LineageMenu = () => {
  const authorized = useAuthorized("lineage");
  return authorized ? (
    <Submenu items={items} icon="shuffle" name="lineage" />
  ) : (
    false
  );
};

export default LineageMenu;
