import _ from "lodash/fp";
import { createSelector } from "reselect";

const configSelector = field =>
  _.find(
    _.conformsTo({
      external_id: _.eq("config_metabase"),
      content: _.conformsTo({
        [field]: _.isNumber
      })
    })
  );

export const getGlossaryDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("dashboard_id")
);

export const getQualityDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("quality_dashboard_id")
);

export const getCatalogDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("catalog_dashboard_id")
);

export const getMetadataDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("metadata_dashboard_id")
);

export const getUsageDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("usage_dashboard_id")
);

export const getPermissionsDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("permissions_dashboard_id")
);

export const getDataStewardsDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("data_stewards_dashboard_id")
);

export const getMarketingDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("marketing_dashboard_id")
);

export const getImpactDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("impact_dashboard_id")
);

export const getEvolutionaryRulesDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("evolutionary_rules_dashboard_id")
);

export const getNaturgyGovernanceDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("gobierno_naturgy_dashboard_id")
);

export const getLineageDashboardConfig = createSelector(
  _.propOr({}, "systemConfigurations"),
  configSelector("lineage_dashboard_id")
);
