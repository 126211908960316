export default {
  //BG
  "concepts.actions.deprecate": "Delete",
  "concepts.status.deprecated": "Deleted",
  "concepts.props.type_label": "Type",

  "filters.agrupacion": "Information Group",
  "filters.confidencialidad": "Confidentiality",
  "filters.criticidad": "Criticality",
  "filters.data_officer": "Data Officer",
  "filters.data_owner": "Data Owner",
  "filters.data_steward": "Data Steward",
  "filters.it_data_stewards": "IT Data Steward",
  "filters.Responsable/Propietario Informe": "Data Steward Report",
  "filters.domain_parents": "Organization",
  "filters.domain": "Organization",
  "filters.domain_ids": "Organization",
  "filters.dominio información": "Management Information",
  "filters.dominio_informacion": "Management Information",
  "filters.dominio_informacion.Si": "Yes",
  "filters.modelo_datos": "Data models",
  "filters.modelo_datos_1": "Data models level 1",
  "filters.modelo_datos_2": "Data models level 2",
  "filters.Mapa_datos_1": "Data Map Level 1",
  "filters.Mapa_datos_2": "Data Map Level 2",
  "filters.nivel_informe": "Report Level",
  "filters.Tipo": "Type",
  "filters.idioma": "Language",
  "filters.naturaleza": "Nature",
  "filters.pais": "Country",
  "filters.sensible": "Sensitive",
  "filters.sujeto_gdpr": "GDPR Compliance",
  "filters.temporalidad": "Temporal aggregation",
  "filters.business_concept_id": "Business Term",
  "filters.domain_id": "Domain",
  "filters.current": "Current filter",
  "filters.DateInPath": "Ingestation date",
  "filters.VersionInPath": "Ingestation language",
  "filters.ColumnSQLStandard": "Supported SQL column",
  "filters.Sep_Thousands": "Miles separator",
  "filters.Sep_Decimal": "Decimal separator",
  "filters.Separator": "Separator",
  "filters.System": "System",
  "filters.Product": "Product",
  "filters.Header": "Header",
  "filters.FullIncInPath": "Type of ingest",
  "filters.Encoding": "Encoding",
  "filters.Scheduled": "Scheduled",
  "filters.SourceType": "Source type",
  "filters.thousand": "Thousand separator",
  "filters.periodicity": "Periodicity",
  "filters.periodicity.Daily": "Daily",
  "filters.periodicity.Monthly": "Monthly",
  "filters.periodicity.Weekly": "Weekly",
  "filters.tags": "Tags",
  "filters.priorization": "Priorization",
  "filters.impact": "Economic impact",

  "header.claim": "Data governance and management tool",

  "structure.lopd": "GDPR Compliance",

  "concepts.props.functional_owner": "Data steward",
  "concepts.props.criticidad": "Criticality",
  "concepts.props.dominio_informacion": "Management Information",
  "concepts.props.direccion_general": "General management",
  "concepts.props.business": "Business",
  "concepts.props.organization": "Organization",

  "source.bc_relacionado": "Related to",
  "target.bc_relacionado": "Related to",
  "source.bc_calculo": "Calculated to",
  "target.bc_calculo": "Calculated to",
  "source.bc_parent": "Father of",
  "target.bc_parent": "Child of",

  "permission.create_acl_entry":
    "Asign a role to an user/group whithin the organization",
  "permission.create_business_concept": "Create Term",
  "permission.delete_business_concept": "Delete terms",
  "permission.deprecate_business_concept": "Deprecate terms",
  "permission.publish_business_concept": "Publish terms",
  "permission.reject_business_concept": "Reject terms",
  "permission.send_business_concept_for_approval":
    "Request business concept approval",
  "permission.view_approval_pending_business_concepts":
    "View business terms pending from approval",
  "permission.view_deprecated_business_concepts":
    "View deprecated business terms",
  "permission.view_draft_business_concepts":
    "View business term in draft state",
  "permission.view_published_business_concepts":
    "View published business concepts",
  "permission.view_rejected_business_concepts":
    "View rejected business concepts",
  "permission.view_versioned_business_concepts":
    "View versioned business concepts",
  "permission.update_acl_entry":
    "Update role of an user/group in a organization",
  "permission.update_business_concept": "Update business terms",
  "permission.manage_confidential_business_concepts": "Confidential terms",
  "permission.manage_business_concept_links": "Links data to terms",
  "permission.manage_confidential_structures": "Confidential structures",

  "login.form.actions.auth0.login": "Login with auth0",
  "login.form.actions.oidc.login": "Login",
  "login.description": "Data Governance",
  "login.form.action.login": "Log in",
  "login.form.or": "Or",
  "login.form.aditionalaccess": "Additional access",

  "permission.create_domain": "Create domains",
  "permission.delete_acl_entry":
    "Delete role of an user/group in a organization",
  "permission.delete_domain": "Delete domains",
  "permission.manage_business_concept_alias": "Edit tags",
  "permission.update_domain": "Update domains",
  "permission.view_domain": "View domains",

  "user.acl.domain": "Organization",

  "validation.header.message.error": "Missing fields to fill!",

  // DD
  "structure.test": "GDPR",

  "structure.type.Field.text": "Field", //Datalake
  "structure.type.Field.icon": "columns",

  "structure.type.structure.text": "Structure", //Datalake
  "structure.type.structure.icon": "table",

  "structure.type.Column.text": "Column", //Datalake/PowerBI
  "structure.type.Column.icon": "columns",

  "structure.type.Table.text": "Table", //PowerBI
  "structure.type.Table.icon": "table",

  "structure.type.Measure.text": "Measure", //PowerBI
  "structure.type.Measure.icon": "tachometer alternate",

  "structure.type.Calculated Table Column.text": "Calculated Column", //PowerBI
  "structure.type.Calculated Table Column.icon": "columns",

  "structure.type.Calculated Column.text": "Calculated Column", //PowerBI
  "structure.type.Calculated Column.icon": "columns",

  "structure.type.Calculated Table.text": "Calculated Table", //PowerBI
  "structure.type.Calculated Table.icon": "table",

  "structure.type.Workspace.text": "Workspace", //PowerBI
  "structure.type.Workspace.icon": "folder",

  "structure.type.worksheet.text": "Worksheet", //PowerBI/Tableau
  "structure.type.worksheet.icon": "sticky note outline",

  "structure.type.workbook.text": "Workbook", //Tableau
  "structure.type.workbook.icon": "book",

  "structure.type.dashboard.text": "Dashboard", //Tableau
  "structure.type.dashboard.icon": "chart bar",

  "structure.type.datasource.text": "Data Source", //Tableau
  "structure.type.datasource.icon": "table",

  "structure.type.measure.text": "Measure", //Tableau
  "structure.type.measure.icon": "tachometer alternate",

  "structure.type.dimension.text": "Dimension", //Tableau
  "structure.type.dimension.icon": "cube",

  "structure.type.Project.text": "Proyect", //MicroStrategy
  "structure.type.Project.icon": "briefcase",

  "structure.type.Document.text": "Document", //MicroStrategy
  "structure.type.Document.icon": "file outline",

  "structure.type.Attribute.text": "Attribute", //MicroStrategy
  "structure.type.Attribute.icon": "cube",

  "structure.type.Metric.text": "Metric", //MicroStrategy
  "structure.type.Metric.icon": "tachometer alternate",

  "structure.type.Folder.text": "Folder", //MicroStrategy/Datalake
  "structure.type.Folder.icon": "folder",

  "structure.type.Report.text": "Report", //MicroStrategy/PowerBI
  "structure.type.Report.icon": "chart bar",

  "structure.type.Database.text": "Database", //SQLServer/Glue
  "structure.type.Database.icon": "database",

  "structure.type.string.text": "String", //Glue
  "structure.type.string.icon": "font",

  "structure.type.int.text": "Integer", //Glue
  "structure.type.int.icon": "hashtag",

  "structure.type.smallint.text": "SmallInt", //Glue
  "structure.type.smallint.icon": "hashtag",

  "structure.type.tinyint.text": "TinyInt", //Glue
  "structure.type.tinyint.icon": "hashtag",

  "structure.type.decimal.text": "Decimal", //Glue
  "structure.type.decimal.icon": "hashtag",

  "structure.type.timestamp.text": "Timestamp", //Glue
  "structure.type.timestamp.icon": "clock outline",

  "structure.type.USER_TABLE.text": "User table", //SQLServer
  "structure.type.USER_TABLE.icon": "table",

  "structure.type.Schema.text": "Schema", //SQLServer
  "structure.type.Schema.icon": "object group outline",

  "structure.type.BASE TABLE.text": "Table", //Redshift
  "structure.type.BASE TABLE.icon": "table",

  "structure.type.VIEW.text": "View", //Redshift
  "structure.type.VIEW.icon": "table",

  "structure.type.character varying.text": "String", //Redshift
  "structure.type.character varying.icon": "font",

  "structure.type.date.text": "Date", //Redshift
  "structure.type.date.icon": "calendar alternate outline",

  "structure.type.integer.text": "Integer", //Redshift
  "structure.type.integer.icon": "hashtag",

  "structure.type.bigint.text": "Bigint", //Redshift
  "structure.type.bigint.icon": "hashtag",

  "structure.type.numeric.text": "Numeric", //Redshift
  "structure.type.numeric.icon": "hashtag",

  "structure.type.timestamp without time zone.text": "Timestamp", //Redshift
  "structure.type.timestamp without time zone.icon": "clock outline",

  "structure.type.External Table.text": "External Table",
  "structure.type.External Table.icon": "table",

  "structure.type.External Column.text": "External Column",
  "structure.type.External Column.icon": "columns",

  "structure.type.PartitionKey.text": "Partition Key",
  "structure.type.PartitionKey.icon": "columns",

  "structure.type.filter.text": "Filter",
  "structure.type.filter.icon": "filter",

  "structure.type.double.text": "double",
  "structure.type.double.icon": "hashtag",

  "structure.field.DataType": "Data type",
  "structure.field.datatype": "Data type",
  "structure.field.menu.link": "Link to term",
  "structure.field.formula": "Formula",
  "structure.field.inserted_at": "Last change at",

  "filters.warm_storage": "Warn Storage",
  "filters.gdpr_table": "Personal Data (GDPR) - Table Level",
  "filters.data_map_1": "Data Map level 1",
  "filters.data_map_2": "Data Map level 2",
  "filters.Datos confidenciales": "Confidencial data",

  "filters.has_links": "Linkage",
  "filters.has_links.Con campos conectados a datos": "With linkaged terms",
  "filters.has_links.Sin campos conectados a datos": "No linkaged terms",
  "filters.has_links.With fields connected to data": "With linkaged terms",
  "filters.has_links.No fields connected to data": "No linkaged terms",

  "filters.sensitivity_table": "Confidential Table",
  "filters.functional_name": "Functional Name",
  "filters.description": "Description",
  "filters.profundidad": "Historical depth",
  "filters.frecuency": "Charging frequency",
  "filters.disponibilidad": "Data Availability",
  "filters.profile_structure_operators": "Profile operators",
  "filters.profile_structure_scheduler": "Profile scheduler",
  "filters.profile_structure_state": "Profile state",
  "filters.campo_calculado": "Campo calculado",

  "filters.class.raw": "Class",
  "filters.class.raw.field": "Field",

  "ruleImplementation.props.campo": "Field",
  "ruleImplementation.props.campo.placeholder": "Select field",

  //IE
  "ingests.props.type": "Type",
  "ingests.props.structure": "Structure",
  "ingests.props.datalake": "Datalake",
  "ingests.props.product": "Product",
  "ingests.props.system": "System",
  "ingests.props.name": "Process",

  "ingests.type.System": "System",
  "ingests.type.Download": "Obfuscation",
  "ingests.type.Delivery": "Delivery",
  "ingests.type.Ingest": "Ingest",

  "ingests.actions.create": "New process",
  "ingests.actions.delete.confirmation.header": "Delete process",
  "ingests.actions.upload.confirmation.header": "Upload process file",
  "ingests.header.duplicate": "Duplicate process",
  "ingestLink.actions.delete.confirmation.content":
    "The link between process and structure will be removed. Are you sure?",
  "ingests.crumbs.top": "Processes",
  "ingests.header": "Processes",
  "ingests.header.edit": "Edit process",
  "ingests.subheader": "See and manage process",
  "ingests.search.placeholder": "Search process...",
  "ingests.search.results.empty": "No processes found for this search",
  "alert.ingestAction.failed.header": "Error creating process",
  "alert.upload.success.content":
    "{count} processes have been sucessfully inserted",
  "navigation.ingests": "Processes",
  "navigation.ingests.ingests": "Processes",
  "navigation.glossary.taxonomy": "Organizations",
  "tabs.ie.ingest": "Process",
  "tabs.ie.relationsIngests": "Related processes",
  "tabs.se.ingests": "Processes",
  "ingest.relatedTo.ingest": "Process related",
  "ingestRelations.relatedDataIngest": "Related processes",

  "ingestsExecutions.props.transmission_time": "Transmission time (mm:ss)",
  "ingestsExecutions.props.status": "Execution status",

  //AppSetup
  "appSetup.welcome.message": "Welcome to Truedat",
  "appSetup.configuration.message":
    "It seems that everything has been installed correctly. Let's begin configuring application so that we can start using it after a few simple steps",
  "appSetup.begin.button": "Begin",

  // ######## Non english variables

  "tabs.bg.relations_business_concept": "Related terms",
  "conceptRelations.relatedConcept": "Related term",
  "conceptRelations.empty": "This term has no related terms currently",
  "relations.actions.business_concept.create": "Add related term",

  "concept.events.add_concept_field":
    "associated a piece of information with the term",
  "concept.events.concept_deprecated": "Erased the term",
  "concept.events.delete_concept_field": "dissasociated a data from the term",

  "concepts.actions.create": "New term",
  "concepts.actions.delete.confirmation.content":
    "This term will be removed from the glossary. Are you sure?",
  "concepts.actions.delete.confirmation.header": "Delete term",
  "concepts.actions.deprecate.confirmation.header": "Delete concept",
  "tabs.bg.concept": "Term",

  "concepts.crumbs.top": "Glossary of terms",
  "concepts.header": "Glossary of terms",
  "concepts.props.domain": "Organizations",
  "concepts.props.name": "Business Terms / Reports",

  "concepts.props.has_rules": "Quality Rules",
  "concepts.props.has_links": "Linkage",
  "concepts.props.last_change_at": "Modifiation date",
  "concepts.subheader": "See and manage business term",
  "concepts.search.placeholder": "Search terms...",

  "conceptLink.actions.delete.confirmation.content":
    "The link between the term and the structure will be removed. Are you sure?",
  "conceptLinks.empty": "This term has no currently linked structures",
  "conceptLink.ou": "Organization",

  "parentConcept.selector.label": "Parent term",

  "domain.error.existing.business.concept":
    "({text}) Cannot delete a domain that contains a business term",

  "domain.selector.label": "Organization",
  "domain.selector.placeholder": "Select an organization...",

  "domains.actions.create": "New organization",
  "domains.search.placeholder": "Search organization...",
  "domains.subheader":
    "Manage business organizations and data governance domains",

  "concepts.rules.empty": "That term has no quality rules",
  "quality.business_concept": "Term",
  "rules.filters.current_business_concept_version": "Term",

  "concepts.has_links.linked_terms": "With fields connected to data",
  "concepts.has_links.not_linked_terms": "No fields connected to data",

  "sidemenu.catalog_dashboard": "Dashboard",
  "sidemenu.metadata_dashboard": "Metadata Dashboard",
  "sidemenu.my_loads": "My Loads",
  "sidemenu.referenceData": "Reference Data",
  "sidemenu.quality_dashboard": "Dashboard",
  "sidemenu.implementations_management": "Drafts",
  "sidemenu.implementations_deprecated": "Deprecated",
  "sidemenu.executions": "My Quality Executions",
  "sidemenu.data_stewards_dashboard": "Dashboard Data Stewards",
  "sidemenu.marketing_dashboard": "Marketing report finder",
  "sidemenu.taxonomy": "Organizations",
  "sidemenu.concepts": "Glossary",
  "sidemenu.structures": "Structures",
  "sidemenu.rules": "Data Quality",
  "sidemenu.ingests": "Process",
  "sidemenu.usage": "Usage",
  "sidemenu.usage_dashboard": "Dashboard",
  "sidemenu.permissions_dashboard": "Permissions dashboard",
  "sidemenu.pending_notes": "Pending Notes",
  "sidemenu.structure_tags": "Tags",
  "sidemenu.structure_types": "Types",
  "sidemenu.info": "Information",
  "sidemenu.impact_dashboard": "Impact dashboard",
  "sidemenu.evolutionary_rules_dashboard": "Evolutionary rules dashboard",
  "sidemenu.naturgy_governance_dashboard": "Naturgy Governance dashboard",
  "sidemenu.info.hover": "Link to Sofia manuals",
  "sidemenu.graphs": "Lineage",

  "tabs.domains": "Organizations",

  "templates.tabs.bg": "Glossary of terms",
  "templates.tabs.gr": "Grants",

  "filters.sensitivity_field": "Sensitive or confidential - Field Level",
  "filters.GDPR_field": "Personal Data (GDPR) - Field Level",

  "structures.header": "Catalog",
  "structures.subheader": "Data catalog as tables or files",

  // Types ingests

  // DQ
  "filters.current_business_concept_version": "Term",
  "filters.periodicidad": "Periodicity",
  "filters.label_domain": "Label Domain",
  "filters.domain_label": "Domain Labels",

  "rule.props.result_type.errors_number": "Number of errors",
  "ruleResult.details.props.Query": "Query",
  "ruleResult.details.props.evidences_sql": "Evidences query",
  "ruleResult.details.props.warning": "Warning",

  "ruleImplementations.props.records": "Records",
  "ruleImplementations.props.errors": "Errors",
  "ruleImplementations.props.dataset": "Structure",

  //DF
  "templates.tabs.ie": "Processes",
  "template.selector.label": "Type",

  //AUTH
  "permission.create_ingest": "Create process",
  "permission.delete_ingest": "Delete process",
  "permission.deprecate_ingest": "Store process",
  "permission.publish_ingest": "Publish process",
  "permission.reject_ingest": "Reject process",
  "permission.send_ingest_for_approval": "Request approval of processes",
  "permission.update_ingest": "Update process",
  "permission.view_approval_pending_ingests": "See process pending approval",
  "permission.view_deprecated_ingests": "View archived processes",
  "permission.view_draft_ingests": "View processes in draft state",
  "permission.view_published_ingests": "View published processes",
  "permission.view_rejected_ingests": "View rejected processes",
  "permission.view_versioned_ingests": "View versioned processes",
  "permission.manage_ingest_relations": "Link data to processes",

  "source.business_concept_to_field_master": "Master relationship",
  "target.business_concept_to_field_master": "Master relationship",

  ingest_to_ingest: "Delivered with",
  "source.ingest_to_ingest": "Delivered with",
  "target.ingest_to_ingest": "Ingested by",

  "ingest.events.relation_deleted": "Relationship removed",

  "ruleResult.props.Evidences": "Evidences"
};
