import React from "react";
import { Container, Sidebar } from "semantic-ui-react";
import {
  Alert,
  Unauthorized as UnauthorizedMessage
} from "@truedat/core/components";
import styles from "styles/navigation.less";
import TopMenu from "./nav/TopMenu";

export const Unauthorized = () => (
  <>
    <TopMenu />
    <Sidebar.Pushable>
      <Sidebar.Pusher>
        <Container className={styles.content} fluid>
          <Alert />
          <UnauthorizedMessage />
        </Container>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  </>
);

export default Unauthorized;
