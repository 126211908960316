import _ from "lodash/fp";
import React from "react";
import { Icon } from "semantic-ui-react";

const UrlEvidence = params => {
  if (!params) return null;

  return (
    <a href={params.evidences} target="_blank" rel="noopener noreferrer">
      <div className="evidences">
        <Icon name={"arrow right"} color="black" />({params.max_rows} rows max.)
        <div className="evidences-warning">
          <i
            aria-hidden="true"
            className="warning sign icon"
            data-toggle="tooltip"
            title="Este link es externo a Sofia.&#013;&#013;&#013;Para poder ver las evidencias es&#013;necesario tener acceso a AWS y&#013;haber entrado en la consola web de&#013;S3 del proyecto antes de pulsar este&#013;link."
          ></i>
        </div>
      </div>
    </a>
  );
};

const initialState = [
  {
    name: "Evidences",
    fieldDecorator: UrlEvidence,
    fieldSelector: _.path("params")
  }
];

export const ruleResultsColumns = (state = initialState) => state;
