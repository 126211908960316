import _ from "lodash/fp";
import React from "react";
import { defaultImplementationColumns } from "@truedat/dq/selectors";

const numberDecorator = number => {
  if (number >= 0) {
    return <span> {number.toLocaleString()} </span>;
  } else "";
};

const extraColumns = [
  {
    name: "errors",
    fieldDecorator: numberDecorator,
    fieldSelector: _.path("execution_result_info.errors"),
    width: 1
  },
  {
    name: "records",
    fieldDecorator: numberDecorator,
    fieldSelector: _.path("execution_result_info.records"),
    width: 1
  }
];

const initialState = [...defaultImplementationColumns, ...extraColumns];

export const ruleImplementationColumns = (state = initialState) => state;
