import React from "react";
import { Submenu } from "@truedat/core/components";
import {
  STRUCTURES,
  SYSTEMS,
  STRUCTURE_TAGS,
  STRUCTURE_TYPES,
  PENDING_STRUCTURE_NOTES,
  STRUCTURES_UPLOAD_EVENTS,
  REFERENCE_DATASETS
} from "@truedat/core/routes";
import { useAuthorized } from "@truedat/core/hooks";
import routes from "../../routes";

const items = [
  {
    name: "structures",
    routes: [STRUCTURES, SYSTEMS],
    permissions: ["data_dictionary"]
  },
  {
    name: "structure_tags",
    routes: [STRUCTURE_TAGS]
  },
  {
    name: "pending_notes",
    routes: [PENDING_STRUCTURE_NOTES],
    permissions: ["data_dictionary"]
  },
  {
    name: "structure_types",
    routes: [STRUCTURE_TYPES]
  },
  {
    name: "catalog_dashboard",
    routes: [routes.CATALOG_DASHBOARD],
    permissions: ["data_dictionary"]
  },
  {
    name: "metadata_dashboard",
    routes: [routes.METADATA_DASHBOARD],
    permissions: ["data_dictionary"]
  },
  {
    name: "my_loads",
    routes: [STRUCTURES_UPLOAD_EVENTS],
    permissions: ["data_dictionary"]
  },
  {
    name: "referenceData",
    routes: [REFERENCE_DATASETS],
    permissions: ["data_dictionary"]
  },
  {
    name: "naturgy_governance_dashboard",
    routes: [routes.NATURGY_GOVERNANCE_DASHBOARD],
    permissions: ["data_dictionary"]
  }
];

const ItemAuthorizer = e => {
  return useAuthorized(e["permissions"]) ? e : null;
};

export const CatalogMenu = () => {
  const filtered = items.filter(ItemAuthorizer);
  return <Submenu items={filtered} icon="block layout" name="catalog" />;
};

export default CatalogMenu;
